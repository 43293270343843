<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Carrito' }, { name: 'Listado' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card style="">
        <v-toolbar-title>Listado de carros de compras</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'CreateCart' }" class="btn__create" color="primary" large>
          <svg style="width:24px;height:24px; margin-right: 10px;" viewBox="0 0 24 24">
              <path fill="currentColor" d="M11,9H13V6H16V4H13V1H11V4H8V6H11M7,18A2,2 0 0,0 5,20A2,2 0 0,0 7,22A2,2 0 0,0 9,20A2,2 0 0,0 7,18M17,18A2,2 0 0,0 15,20A2,2 0 0,0 17,22A2,2 0 0,0 19,20A2,2 0 0,0 17,18M7.17,14.75L7.2,14.63L8.1,13H15.55C16.3,13 16.96,12.59 17.3,11.97L21.16,4.96L19.42,4H19.41L18.31,6L15.55,11H8.53L8.4,10.73L6.16,6L5.21,4L4.27,2H1V4H3L6.6,11.59L5.25,14.04C5.09,14.32 5,14.65 5,15A2,2 0 0,0 7,17H19V15H7.42C7.29,15 7.17,14.89 7.17,14.75Z" />
          </svg>
          Tomar pedido
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm6>
            <v-text-field v-model="search" box label="Buscar" clearable hide-details />
          </v-flex>           
          <v-flex sm2 xs12>
            <v-btn
              :loading="loading4"
              :disabled="loading4"
              color="primary"
              large
              block
              @click="searchData"
              style="font-weight:700; "
            >
            <svg style="width:24px;height:24px; margin-right:10px;" viewBox="0 0 24 24">
            <path fill="currentColor" d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
            </svg>
              Buscar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
          </v-flex>
          <v-flex sm4 xs12>
              <v-btn                                
                large                                               
                color="success"
                :loading="loadingUpdate"
                tile
                @click="actualizarBtn"
                title="Actualizar"
              >
                <v-icon dark>
                  cached
                </v-icon>
              </v-btn>
              <v-btn color="error" large @click="clearSearch" title="Limpiar filtros">
              <v-icon dark>
                  delete
                </v-icon>
            </v-btn>
          </v-flex>  
          <v-flex sm6 xs12>
            <template>
              <v-expansion-panel focusable>
                <v-expansion-panel-content>
                  <template v-slot:header>
                    <div>Filtros</div>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-layout row wrap>
                        <v-flex sm4>
                          <v-text-field v-model="desde" label="fecha-inicio" type="date" outline />
                        </v-flex>
                        <v-flex sm4>
                          <v-text-field v-model="hacia" label="fecha-final" type="date" outline />
                        </v-flex>
                        <v-flex sm4>
                          <v-autocomplete
                            v-model="state"
                            :items="estados"
                            dense
                            outline
                            clearable
                            small-chips
                            label="Seleccionar estado"
                          />
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </template>
          </v-flex>
          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'N° Orden' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Total' },
                { text: 'Estado', value: 'pago.estado_pago' },
                { text: 'Acciones' },
                { text: 'Ciudad', value: 'ciudad' },
                { text: 'Cliente', value: 'nombre' },
                { text: 'Etapa Actual', value: 'etapaActual.nombre' },
                { text: 'N° Boleta', value: 'pago.num_documento' },
                { text: 'Courier', value: 'courier_nombre' },
                { text: 'N° Sgmto', value: 'num_seguimiento' }
              ]"
              :items="carts"
              :loading="loading"
              hide-actions
              :pagination.sync="pagination"
              class="elevation-1"              
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.numero_orden }}
                </td>
                <td class="px-3">
                  {{ props.item.created_at }}
                </td>
                <td class="px-3" style="color:black">$ {{ formatMoney(props.item.precio_total) }}</td>
                <td class="px-3">
                  <v-chip
                    v-if="props.item.pago.estado_pago === 'PAGADO'"
                    small
                    class="text-xs-center"
                    color="success"
                    text-color="white"
                    center
                    style="letter-spacing:1.8px; font-weight:600;width: 110px;"
                  >
                    {{ props.item.pago.estado_pago }}
                  </v-chip>
                  <v-chip
                    v-else-if="props.item.pago.estado_pago === 'TRANSACCION FALLIDA'"
                    small
                    center
                    color="error"
                    text-color="white"
                    style="font-weight:600;width: 110px;"
                  >
                    Tr. Fallida
                  </v-chip>
                  <v-chip v-else small color="error" text-color="white" style="font-weight:600;width: 110px;" center>
                    {{ props.item.pago.estado_pago }}
                  </v-chip>
                </td>
                <td class="px-3">
                  <v-tooltip right min-width="280">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="ma-0"                        
                        small
                        round
                        :loading="props.item.id === id ? loadingDetalle : false"
                        color="primary"
                        @click="openModalDetailCart(props.item)"
                        v-on="on"
                      >
                        Ver detalle
                      </v-btn>
                    </template>
                    <span style="font-size:16px;line-height:36px;"
                      >N° Orden: {{ props.item.numero_orden }}
                      <br />
                      Total: ${{ formatMoney(props.item.precio_total) }}
                      <br />
                      Etapa: {{ props.item.etapaActual.nombre }}
                      <br />
                      Estado de pago: {{ props.item.pago.estado_pago }}
                      <br />
                      N° de boleta: {{ props.item.pago.num_documento }}
                      <br />
                      Forma de pago: {{ props.item.pago.tipo }}
                      <br />
                      Cod. autorización: {{ props.item.pago.codigo_autorizacion }}
                      <br />
                      Courier: {{ props.item.courier_nombre }}
                      <br />
                      N° de Seguimiento: {{ props.item.num_seguimiento }}
                      <br />
                      Peso total: {{ props.item.peso_total }} kgs.
                    </span>
                  </v-tooltip>
                </td>
                <td class="px-3">
                  {{ props.item.ciudad }}
                </td>
                <td class="px-3" v-if="props.item.nombre">
                  <v-tooltip right max-width="300">
                    <template v-slot:activator="{ on }">
                      <v-chip color="blue" text-color="white" dark v-on="on" small>
                        <v-avatar>
                          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M15.5,12C18,12 20,14 20,16.5C20,17.38 19.75,18.21 19.31,18.9L22.39,22L21,23.39L17.88,20.32C17.19,20.75 16.37,21 15.5,21C13,21 11,19 11,16.5C11,14 13,12 15.5,12M15.5,14A2.5,2.5 0 0,0 13,16.5A2.5,2.5 0 0,0 15.5,19A2.5,2.5 0 0,0 18,16.5A2.5,2.5 0 0,0 15.5,14M10,4A4,4 0 0,1 14,8C14,8.91 13.69,9.75 13.18,10.43C12.32,10.75 11.55,11.26 10.91,11.9L10,12A4,4 0 0,1 6,8A4,4 0 0,1 10,4M2,20V18C2,15.88 5.31,14.14 9.5,14C9.18,14.78 9,15.62 9,16.5C9,17.79 9.38,19 10,20H2Z" />
</svg>
                        </v-avatar>
                        {{ props.item.nombre }} {{ props.item.apellidos }}
                      </v-chip>
                    </template>
                    <span style="font-size:16px;line-height:36px;"
                      >Nombre: {{ props.item.nombre }} {{ props.item.apellidos }}
                      <br />
                      E-mail: {{ props.item.cliente.email }}
                      <br />
                      Teléfono: {{ props.item.telefono }}
                      <br />
                      Dirección: {{ props.item.direccion }}
                      <br />
                      Ciudad: {{ props.item.ciudad }}
                    </span>
                  </v-tooltip>
                </td>
                <td class="px-3" v-else>
                  {{ props.item.cliente.nombre }}
                </td>
                <td class="px-3">
                  {{ props.item.etapaActual.nombre }}
                </td>
                <td class="px-3">{{ props.item.pago.num_documento }}</td>
                <td class="px-3">
                  {{ props.item.courier_nombre }}
                </td>
                <td class="px-3" style="text-align:center;">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" v-if="props.item.num_seguimiento > 0" color="green">
                        check_circle
                      </v-icon>
                    </template>
                    <span>N°: {{ props.item.num_seguimiento }} </span>
                  </v-tooltip>
                </td>
              </tr>
            </v-data-table>

            <div class="text-xs-center pt-2 md4 lg4">
              <v-pagination
                @input="paginationChangeHandler"
                v-model="pagination.pag1"
                :length="pages"
                :total-visible="7"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
    <ModalDetailCart :carts="cartsDetail" :e6="e6" :id="id" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Listado de Productos" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
    ModalDetailCart: () => import("@/views/cart/DetailCart")
  },

  data() {
    return {
      search: "",      
      button_id: null,
      state: "",
      loadingUpdate: false,
      loading: true,
      loadingDetalle: false,
      loading4: false,
      openCart: false,
      pages: "",
      estados: ["Pagado", "Pendiente", "Transaccion fallida", "Anulado"],
      pagination: { totalItems: 0, rowsPerPage: 15, pag1: 1 },
      carts: [],
      cartsDetail: [],
      e6: 0,
      id: ""
    };
  },

  computed: {
    ...mapState({}),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user"
    })
  },
  created() {
    this.getData();
  },

  methods: {
    ...mapActions({
      replaceShowModalDetailCart: "carts/replaceShowModalDetailCart",
      getDetailCart: "detailCart/getDetailCart"
    }),
    async openModalDetailCart(cart) {
      this.loadingDetalle = true;
      this.button_id = cart.id;
      this.id = cart.id;
      this.openCart = true;
      let url = `${HOST}/carros/${cart.id}/etapas`;
      await axios.get(url).then(response => {
        this.cartsDetail = response.data.data;
        this.e6 = this.cartsDetail.length;
      });
      await this.getDetailCart({ cartId: cart.id });
      this.openCart = false;
      this.loadingDetalle = false;
      this.replaceShowModalDetailCart({ status: true });
      
    },
    clearSearch() {
      this.search = "";
      this.desde = "";
      this.hacia = "";
      this.state = "";
      this.getData();
    },
    actualizarBtn(){
      this.loadingUpdate=true;      
      this.getData();     
      this.loadingUpdate=false;
    },
    formatMoney(numero) {
      return new Intl.NumberFormat(["ban", "id"]).format(numero);
    },
    searchData() {
      this.loading4 = true;
      this.pagination.pag1 = 1;
      let search = "";
      if (this.search) {
        search = this.search;
      }
      let state = "";
      if (this.state) {
        state = this.state;
      }
      let desde = "";
      let hacia = "";
      if (this.desde || this.hacia) {
        desde = this.desde;
        hacia = this.hacia;
      }
      axios
        .get(
          `${HOST}/carros?page=${this.pagination.pag1}&search=${search}&desde=${desde}&hacia=${hacia}&estado_pago=${state}`
        )
        .then(response => {
          this.loading4 = false;
          this.carts = response.data.data;
          this.pagination.totalItems = response.data.meta.total;
          this.pagination.rowsPerPage = response.data.meta.per_page;
          this.pagination.pag1 = response.data.meta.current_page;
          this.pages = response.data.meta.last_page;
        });
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.pag1 = pageNumber;
      this.getData();
    },
    getData() {
      let search = "";
      if (this.search) {
        search = this.search;
      }
      let state = "";
      if (this.state) {
        state = this.state;
      }
      let desde = "";
      let hacia = "";
      if (this.desde || this.hacia) {
        desde = this.desde;
        hacia = this.hacia;
      }
      let url = `${HOST}/carros?page=${this.pagination.pag1}&search=${this.search}&desde=${desde}&hacia=${hacia}&estado_pago=${state}`;
      axios.get(url).then(response => {
        this.carts = response.data.data;
        this.loading = false;
        this.pagination.totalItems = response.data.meta.total;
        this.pagination.rowsPerPage = response.data.meta.per_page;
        this.pagination.pag1 = response.data.meta.current_page;
        this.pages = response.data.meta.last_page;
      });
    }
  }
};
</script>
<style>
.v-btn {
  text-transform: none !important;  
  font-weight:700;
  padding:16px 18px !important;
}
.elevation-1 .v-chip {
  margin: auto;
  font-size: 0.8em;
  padding:14px;  
  letter-spacing: 0.5px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
